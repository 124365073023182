import { TaskPlan } from "./task_plan";

export class AsapStrategy implements ISchedulingStrategy {
	static Create(gantt: any): AsapStrategy {
		const instance = new AsapStrategy();
		instance._gantt = gantt;
		return instance;
	}

	protected _gantt: any;

	resolveRelationDate(
		taskId: TaskID,
		adjacentLinks: ITaskRelations,
		plansHash: IPlansHash
	): TaskPlan {
		let minStart = null;
		let linkId = null;

		let defaultStart = null;
		const task = this._gantt.getTask(taskId);
		const relations = adjacentLinks.predecessors;

		let minRelationDate = null;
		for (let i = 0; i < relations.length; i++) {
			const relation = relations[i];

			// .preferredStart still exists only to emulate pre 6.1 auto scheduling behavior
			// will be removed in future versions
			// TODO: remove .preferredStart in v7.0
			defaultStart = relation.preferredStart;

			const constraintDate = this.getEarliestStartDate(
				relation,
				plansHash,
				task
			);

			if (this.isSmallerOrDefault(minRelationDate, constraintDate, task)) {
				minRelationDate = constraintDate;
			}
			if (
				this.isSmallerOrDefault(defaultStart, constraintDate, task) &&
				this.isSmallerOrDefault(minStart, constraintDate, task)
			) {
				minStart = constraintDate;
				linkId = relation.id;
			}
		}

		if (!relations.length && this._gantt.config.project_start) {
			if (this.isSmallerOrDefault(task.start_date, this._gantt.config.project_start, task)) {
				minStart = this._gantt.config.project_start;
			}
		}

		let maxEnd = null;
		if (minStart) {
			minStart = this._gantt.getClosestWorkTime({
				date: minStart,
				dir: "future",
				task
			});
			maxEnd = this._gantt.calculateEndDate({
				start_date: minStart,
				duration: task.duration,
				task
			});
		}

		const masterPlan = plansHash[taskId];
		const currentPlan = TaskPlan.Create(masterPlan);

		currentPlan.link = linkId;
		currentPlan.task = taskId;
		currentPlan.start_date = minStart;
		currentPlan.end_date = maxEnd;
		currentPlan.kind = "asap";

		if (minRelationDate) {
			currentPlan.earliestSchedulingStart = minRelationDate;
			currentPlan.earliestSchedulingEnd = this._gantt.calculateEndDate({
				start_date: minRelationDate,
				duration: task.duration,
				task
			});
		}

		return currentPlan;
	}

	protected isEqual(dateA: Date, dateB: Date, task: ITask): boolean {
		return !this._gantt._hasDuration(dateA, dateB, task);
	}

	protected isFirstSmaller(small: Date, big: Date, task: ITask): boolean {
		if (small.valueOf() < big.valueOf() && !this.isEqual(small, big, task)) {
			return true;
		}
		return false;
	}

	protected isSmallerOrDefault(smallDate: Date, bigDate: Date, task: ITask): boolean {
		return !!(!smallDate || this.isFirstSmaller(smallDate, bigDate, task));
	}

	protected getPredecessorEndDate(id: TaskID, plansHash: IPlansHash): Date {
		const plan = plansHash[id];
		const task = this._gantt.getTask(id);
		let res;

		if (!(plan && (plan.start_date || plan.end_date))) {
			res = task.end_date;
		} else if (plan.end_date) {
			res = plan.end_date;
		} else {
			res = this._gantt.calculateEndDate({
				start_date: plan.start_date,
				duration: task.duration,
				task
			});
		}

		return res;
	}

	protected getEarliestStartDate(relation: IInternalLink, plansHash: IPlansHash, task: ITask): Date {
		const predecessorEnd = this.getPredecessorEndDate(
			relation.source,
			plansHash
		);
		const successor = task;

		let successorStart = this._gantt.getClosestWorkTime({
			date: predecessorEnd,
			dir: "future",
			task: successor
		});

		if (
			predecessorEnd &&
			relation.lag &&
			relation.lag * 1 === relation.lag * 1
		) {
			successorStart = this._gantt.calculateEndDate({
				start_date: predecessorEnd,
				duration: relation.lag * 1,
				task: successor
			});
		}

		return successorStart;
	}
}
